<template>
    <div class=" font-bodyFont">
        <div>
            <ul class="cards">
                <li v-for="item in membersList" :key="item.id">
                    <router-link :to="{ name: 'prize', params: {id: item.id}}" class="card xl:h-[500px] md:h-[450px] border grid place-content-center">
                    <img :src="item.image" class="p-2 card__image xl:h-[350px] md:h-[350px] sm:h-[250px] xl:-mt-[150px] md:-mt-[150px] sm:-mt-[0px] md:mb-0 sm:mb-[190px]" alt="" />
                    <div class="card__overlay bg-[#87764E]">
                        <div class="card__header bg-[#87764E]">
                        <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
                        <img class="card__thumb" :src="item.representativeimg" alt="" />
                        <div class="card__header-text">
                            <h3 class="card__title md:text-[20px] sm:text-[18px] font-heading1" >{{ item.PrizeName }}</h3>            
                            <span class="card__status md:text-[18px] sm:text-[18px] font-heading1" >{{item.representativeName}}</span><br>
                            <!-- <span class="card__status text-[#87764E]">{{item.representativeOf}}</span> -->
                        </div>
                        </div>
                        <p class="card__description text-[#87764E]" > {{item.representativeOf}}</p>
                    </div>
                </router-link>   
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { Members } from "./MembersList";
export default{
    
    data() {
        return{
            membersList:Members,
        }
    }
}
</script>

<style>
    :root {
    --surface-color: #fff;
    --curve: 40;
    }

    * {
    box-sizing: border-box;
    }

    /* body {
    font-family: 'Noto Sans JP', sans-serif;
    background-color: #fef8f8;
    } */

    .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;

    }
    @media only screen and (min-width: 1300px) {
        .cards {
            grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
            
            }
    }

    .card {
    position: relative;
    display: block;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
    }

    .card__image {      
    width: inherit;
    /* height: auto; */
    }

    .card__overlay {
        
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: var(--surface-color);      
    transform: translateY(100%);
    transition: .2s ease-in-out;
    }

    .card:hover .card__overlay {
    transform: translateY(0);
    }

    .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
    height: inherit;
    }

    .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
    }

    .card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
    }       

    .card:hover .card__header {
    transform: translateY(0);
    }

    .card__thumb {
    flex-shrink: 0;
    width: 60px;
    height: 60px;      
    border-radius: 50%;      
    }

    .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #fff;
    }

    .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #fff;  
    }

    .card__status {
    font-size: .8em;
    color: #fff;
    }

    .card__description {
    padding: 0 0.8em 0.8em;
    margin: 0;
    color: #fff;
    /* font-family: "MockFlowFont";    */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    }
</style>