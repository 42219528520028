<template>
    <div class=" font-bodyFont">
        
        <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center  text-[#8C7959] font-heading1">الأخبار</div>
        <!-- <div v-for="item in lastThreeItems" :key="item.id " class=" text-start">
        <router-link :to="{ name: 'NewsInfo', params: {id: item.id}}">
            <div class="grid md:grid-flow-col md:auto-cols-auto gap-0 xl:gap-y-4 xl:p-0">
                <div>
                    <img class="rounded-lg w-[300px]" :src="item.newsImg" alt="">
                </div>
                <div>
                    <div class="font-heading1 text-start" style="white-space: pre-wrap;">
                        {{ item.newsheading }} 
                    </div>
                </div>
            </div>
        </router-link>
        </div> -->





        <div v-for="item in lastThreeItems" :key="item.id">
            <router-link :to="{ name: 'NewsInfo', params: {id: item.id}}">
                <div class="grid md:auto-cols-auto md:grid-flow-col xl:mt-10 md:mt-24 sm:mt-20  md:mx-5 sm:mx-7 xl:place-content-start"> 
                    <div>
                        <!-- <img class="md:w-[300px] rounded-lg" :src="require(`../../assets/news/${item.newsImg}`)" alt=""> -->
                        <img class="m-0 md:w-[300px] rounded-lg" :src="item.newsImg" alt="">
                    </div>
                    <div class="  xl:ml-44 xl:mt-3 md:mt-1 md:mx-2 sm:mt-5 sm:text-center md:text-start">
                        <div class="xl:text-[23px] md:text-[22px] sm:text-[20px]  font-heading1" style="white-space: pre-wrap;">
                            {{ item.newsheading }}
                        </div>
                        <div class=" xl:mt-5 md:mt-1 xl:text-[22px] md:text-[20px] sm:text-[20px] text-justify xl:leading-normal">
                            {{ item.newsdesc}}
                        </div>
                    </div>
                    
                </div>
            </router-link>
            <div class="grid place-content-center my-3  bg-slate-200 h-1 w-[90%] mx-[2%]">
                <img src="" alt="">
            </div> 
        </div>

    </div>
</template>
<script>
    import { news } from './newsList';
    export default {
    data() {
        return {
            newsList:news,
        };
        
    },
        computed: {
      lastThreeItems() {
          return this.newsList.slice(-8).reverse()
      }
    }

    }
</script>
<style>
</style>