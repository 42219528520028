<template>
    <div class=" font-bodyFont">
        <div v-if="newsdetail">
            <div class=" grid place-content-center md:mt-32 sm:mt-28">
                <!-- <img class="h-auto md:w-[500px] sm:w-[400px]" :src="require(`../../assets/news/${item.newsImg}`)" alt=""> -->
                <img class="h-auto xl:w-[500px] sm:w-[400px] rounded-lg" :src="newsdetail.newsImg" alt="">
            </div>
            <div class=" xl:text-[25px] md:text-[30px] sm:text-[20px] text-center text-[#8C7959] md:mt-32 sm:mt-28 font-heading1"> {{ newsdetail.newsheading }}</div>
            <div class=" text-justify md:mt-10 sm:mt-6 xl:text-[25px] md:text-[24px] sm:text-[20px] md:mx-32 sm:mx-6 leading-relaxed" style="white-space: pre-line">
                {{ newsdetail.newsDetails }}
            </div>
            <div class="grid md:grid-flow-col gap-16 place-content-center">
                <img class="h-auto xl:w-[500px] sm:w-[400px] rounded-lg md:mt-10" :src="newsdetail.extraImg" alt="">
                <img class="h-auto xl:w-[500px] sm:w-[400px] rounded-lg md:mt-10" :src="newsdetail.extraImg1" alt="">
            </div>
            <div class="grid md:grid-flow-col gap-16 place-content-center">
                <img class="h-auto xl:w-[800px] sm:w-[400px] rounded-lg md:mt-10" :src="newsdetail.extraImg2" alt="">
            </div>
        </div>
        
        <div class="mt-20"></div>
    </div>
</template>
<script>
    import { news } from './newsList';
    export default {

        props:{
            id: {type: Number, default: null},
        },
        data () {
            return {
                newsdetail: news[this.id-1]
            }
        },
    }
</script>
<style>
</style>