<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">الدورة الثالث 6 اكتوبر 2021</div>
            <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3">
                <div>
                    <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting1/33.jpg" alt=""/>
                </div>
                <div>
                    <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting3/20.jpg" alt=""/>
                </div>
            </div>
            <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">عقدت الجمعية العمومية اجتماعها الثالث بتاريخ 7 أكتوبر 2021، بحضور الجوائز أعضاء الجمعية. طرحت عدة موضوعات للمناقشة، وتم ما يلي: 
                <br>
                <ul class=" list-outside list-disc">
                    <li>عرض الموقع الإلكتروني المحدث للمنتدى، والموافقة عليه، بعد استكمال التعديلات التي طرحها الأعضاء.</li>
                    <li>قرير عن الندوات الافتراضية.</li>
                    <li>قدم الأستاذ الدكتور هشام عزمي الأمين العام للمجلس الأعلى للثقافة في مصر تقريرًا عن واقع الجوائز العربية (أعضاء المنتدى)، ومؤشرات توجهاتها وحضورها في الثقافة العربية.</li>
                    <li>إحاطة السادة الأعضاء بانضمام جوائز جديدة.</li>
                </ul>
                وتم عقد اجتماع مع مدير عام المنظمة العربية للتربية والثقافة والعلوم (الألكسو) معالي الأستاذ الدكتور محمد ولد أعمر، وبحضور الأستاذ هاني المقبل، رئيس المجلس التنفيذي للألكسو، والأستاذ هتان بن سمان، الأمين العام للجنة الوطنية السعودية للتربية والثقافة والعلوم.<br>
                وتمت مناقشة أوجه التعاون بين المنتدى والمنظمة من حيث الوسائل الممكنة، والطريقة الأفضل للتعاون بين المؤسسات الثقافية العربية، الحاضرة في الاجتماع، ومنظمة الألكسو. وتم طرح فكرة توقيع مذكرة تفاهم بين الألكسو ومنتدى الجوائز العربية.
            </div>
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold mt-[80px]">نشاطات الدورة الثانية</div>
            <div class="grid place-content-center">
                <img class=" mt-20 xl:w-[1000px] xl:h-[800px] md:w-[800px]" src="../assets/meetingsPage/meeting3/1.png" alt="">
            </div>
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">برعاية وحضور صاحب السمو الملكي الأمير خالد الفيصل، الرئيس الفخري لمنتدى الجوائز العربية، انطلقت الدورة الثالثة للمنتدى في السادس من أكتوبر 2021، بمعرض الرياض الدولي للكتاب، بحضور معالي مدير عام المنظّمة العربية للتربية والثقافة والعلوم “الألكسو” الأستاذ الدكتور محمد ولد أعمر، الذي أهدى بدوره درعاً لسمو الأمير خالد، شكراً لجهوده ودعمه المستمر للعلوم والثقافة العربية.

            وكرّم سموّه خلال افتتاح الدورة الثالثة؛ ثمانيةً من روّاد الجوائز العربية. كما نظّم المنتدى في المساء نفسه ندوة ثقافية بعنوان (المرأة والجوائز العربية).

            <br>وفي السابع من أكتوبر 2021؛ عقد المنتدى اجتماعه الثالث للجمعية العمومية بمقر جائزة الملك فيصل بالرياض، كما عُقد لقاء بين أعضاءه ومدير عام المنظّمة العربية للتربية والثقافة والعلوم “الألكسو”، بحضور رئيس المجلس التنفيذي للمنظّمة، والأمين العام للجنة الوطنية السعودية للتربية والثقافة والعلوم.</div>
        <!-- <div class=" text-center text-[#8C7959] md:text-3xl sm:text-2xl mt-20 font-heading1">الندوة الأولى: الواقع والرؤى المستقبلية</div> -->
        <div class=" mt-20 grid place-content-center">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/7VmDm0HbGKg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] mt-20 font-heading1">المكرمون</div>
        <div class=" mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] text-center leading-relaxed"> كرّم صاحب السمو الملكي الأمير خالد الفيصل، الرئيس الفخري لمنتدى الجوائز العربية، ثمانيةً من روّاد الجوائز العربية. لقاء ما بذلوه من جهود متميزة خلال عملهم في الجوائز، ما جعلها تُحقق حضوراً علمياً وثقافياً بارزاً.</div>

        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/1.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/4-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور أسعد عبدالرحمن</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/2.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/3-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور فهد السماري</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/3.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/2-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور جابر عصفور</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/4.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/1-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور أحمد الضبيب</div>
            </div>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/5.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/8-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور سعيد السعيد</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/6.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/7-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ ساسي حمام</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/7.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/6-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ الدكتور حسن نجمي</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/8.pdf" target="_blank">
                    <img class="h-[250px] rounded-t-md" src="../assets/meetingsPage/meeting3/5-01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">الأستاذ مجذوب عيدروس</div>
            </div>
        </div>


        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">ندوة المرأة والجوائز العربية</div>
            <img class=" mt-20 xl:w-[1200px] xl:h-[1000px] md:w-[800px]§" src="../assets/meetingsPage/meeting3/pic1.jpg" alt="">
        </div>
        <div class="text-center mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">نظّم منتدى الجوائز العربية ليلة افتتاح دورته الثالثة ندوة ثقافية بعنوان (المرأة والجوائز العربية). ناقشت حضور وغياب المرّأة في الجوائز العربية. بحضور عددٍ من المثقفين والمثقفات والمهتمين في الجوائز العربية وأدوارها.</div>
        <div class=" grid place-content-center mt-20">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/vNbhgvKEh6I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/9.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/meetingsPage/meeting3/04.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">منصورة عز الدين</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/10.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/meetingsPage/meeting3/02.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">شَهلا العُجيلي</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/11.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/meetingsPage/meeting3/01.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">واسيني الأعرج</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/12.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/meetingsPage/meeting3/03.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">صالح المحمود</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/meeting3/13.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/meetingsPage/meeting3/05.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">فاطمة الصايغ</div>
            </div>
        </div>
        <!-- <div class=" text-center text-[#8C7959] text-2xl mt-20">صور اللقاء</div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting3/pic4.jpg" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting3/pic3.jpg" alt=""/>
            </div>
        </div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-10 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting3/pic2.jpg" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting3/pic1.jpg" alt=""/>
            </div>
        </div> -->
        <div class="mt-20"></div>
    </div>
</template>