import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'
import MembersPage from '../views/MembersPages/MembersPage.vue'
import AboutPage from '../views/AboutPage.vue'
import NewsPage from '../views/newspages/NewsPage.vue'
import IssuesPage from '../views/IssuesPage.vue'
import ActivitiesPage from '../views/ActivitiesPage.vue'
import PrizePage from '../views/PrizePage.vue'
import MeetingPage from '../views/meetingPage'
import seminarsPage from '../views/SeminarsPage'
import FirstMeeting from '../views/firstMeeting.vue'
import SecondMeeting from '../views/secondMeeting.vue'
import ThirdMeeting from '../views/thirdMeeting.vue'
import FirstSeminar from '../views/firstSeminar.vue'
import SecondSeminar from '../views/secondSeminar.vue'
import ThirdSeminar from '../views/thirdSeminar.vue'
import NewsInfo from '../views/newspages/NewsInfo.vue'
import Workshop from '../views/workshopPage.vue'
import FirstWorkshop from '../views/firstWorkshop.vue'
import FirstConferenc from '../views/firstConferenc.vue'
import SecondConferenc from '../views/secondConferenc.vue'
import ThirdConferenc from '../views/thirdConferenc.vue'
import FourthMeeting from '../views/fourthMeeting.vue'

 
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/members',
      name: 'memberspage',
      component: MembersPage,
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage,
    },
    {
      path: '/news',
      name: 'newsPage',
      component: NewsPage,
    },
    {
      path: '/issues',
      name: 'issues',
      component: IssuesPage,
    },
    {
      path: '/activities',
      name: 'activities',
      component: ActivitiesPage,
    },
    {
      path: '/prize/:id',
      name: 'prize',
      component: PrizePage,
      props: true,
    },
    {
      path: '/meeting',
      name: 'Meeting',
      component: MeetingPage,
    },
    {
      path: '/seminars',
      name: 'Seminars',
      component: seminarsPage,
    },
    {
      path: '/firstMeeting',
      name: 'FirstMeeting',
      component: FirstMeeting,
    },
    {
      path: '/secondMeeting',
      name: 'SecondMeeting',
      component: SecondMeeting,
    },
    {
      path: '/thirdMeeting',
      name: 'ThirdMeeting',
      component: ThirdMeeting,
    },
    {
      path: '/firstSeminar',
      name: 'FirstSeminar',
      component: FirstSeminar,
    },
    {
      path: '/secondSeminar',
      name: 'SecondSeminar',
      component: SecondSeminar,
    },
    {
      path: '/thirdSeminar',
      name: 'ThirdSeminar',
      component: ThirdSeminar,
    },
    {
      path: '/news/:id',
      name: 'NewsInfo',
      component: NewsInfo,
      props: true
    },
    {
      path: '/workshop',
      name: 'Workshop',
      component: Workshop,
    },
    {
      path: '/firstWorkshop',
      name: 'FirstWorkshop',
      component: FirstWorkshop,
    },
    {
      path: '/firstConferenc',
      name: 'FirstConferenc',
      component: FirstConferenc,
    },
    {
      path: '/secondConferenc',
      name: 'SecondConferenc',
      component: SecondConferenc,
    },
    {
      path: '/thirdConferenc',
      name: 'ThirdConferenc',
      component: ThirdConferenc,
    },
    {
      path: '/fourthMeeting',
      name: 'FourthMeeting',
      component: FourthMeeting,
    },
  ],
});

export default router;
