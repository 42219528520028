<template>
    <div class="font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">الدورة الثانية 2-3 أكتوبر 2019</div>
            <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3">
                <div>
                    <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/2.jpg" alt=""/>
                </div>
                <div>
                    <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/3.jpg" alt=""/>
                </div>
            </div>

            <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">عقدت الجمعية العمومية اجتماعها الثاني بتاريخ 3 أكتوبر 2019، بحضور الجوائز أعضاء الجمعية. تم في الاجتماع النظر في اللائحة التنظيمية ومناقشتها باستفاضة وطرح العديد من الرؤى حول مواد اللائحة، ثم تمت الموافقة عليها بعد استكمال التعديلات التي تم طرحها في الاجتماع.  كما تم استعراض شعارات مقترحة للمنتدى، والشعار الموصى به من المجلس التنفيذي. وبعد مداولات الأعضاء، تم إقرار الشعار الذي أوصى به المجلس التنفيذي. ثم تم الاطلاع على البوابة الإلكترونية، وما تحتويه من معلومات وأنشطة، وتمت الموافقة على إطلاقها خلال الأيام القادمة، بعد الأخذ بالمقترحات التي أقرها المجلس.  أخيرًا وجه الحاضرون الشكر والتقدير لصاحب السمو الملكي الأمير خالد الفيصل</div>
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold mt-[80px]">نشاطات الدورة الثانية</div>
            <div class=" grid place-content-center">
                <img class=" mt-20" src="../assets/meetingsPage/meeting2/1.jpeg" alt="">
            </div>
        </div>
        
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">برعاية صاحب السمو الملكي الأمير خالد الفيصل مستشار خادم الحرمين الشريفين، أمير منطقة مكة المكرمة، رئيس هيئة جائزة الملك فيصل، عقد في مدينة الرياض، في الثالث من أكتوبر 2019 الاجتماع الثاني للجمعية العمومية لمنتدى الجوائز العربية في مقر جائزة الملك فيصل وقد أقرت الجمعية العمومية اللائحة التنظيمية للمنتدى وشعار المنتدى. وعلى هامش دورة تم تنظيم ندوة بعنوان: “الجوائز العربية بين الشعر والسرد”.</div>
        <div class=" text-center text-[#8C7959] md:text-3xl sm:text-2xl mt-20 font-heading1">ندوة الجوائز العربية بين الشعر والسرد</div>
        <div class=" mt-20 grid place-content-center">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/QUUwrFw1oQ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/2/1.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting2/talb-150-150x150-1.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">طالب الرفاعي</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/2/2.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting2/rowdah-150-150x150-1.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">روضة الحاج</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/2/3.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting2/omemah-150-150x150-1.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">أميمة الخميس</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/2/4.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting2/ali-150-150x150-1.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">علي عبدالله خليفة</div>
            </div>
        </div>
        <div class=" text-center text-[#8C7959] text-2xl mt-20 font-heading1">صور اللقاء</div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/DSC4422.png" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/DSC4673.png" alt=""/>
            </div>
        </div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-10 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/DSC4676.png" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting2/DSC4677.png" alt=""/>
            </div>
        </div>
        <div class="mt-20"></div>
    </div>
</template>