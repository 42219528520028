<template>
    <div class="xl:mx-[30px]">
        <!-- <div class="xl:text-4xl sm:text-3xl text-center xl:mt-28 sm:mt-20 text-[#796F58]">عن المنتدى</div> -->
        <div class="xl:mt-[190px] md:mt-[200px] sm:mt-[80px]"></div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl: gap-[100px] mt-[100px] font-bodyFont">
          <div class="card xl:w-[650px] sm:w-[400px]">
              <div class="img"> <img src="../assets/aboutpage/2.png" alt=""></div>
              <div class="content">
                  <h3 class=" text-center">اللائحة التنظيمية</h3>
                  <a href="files/about/2.pdf" target="_blank">
                      <button class="mt-5 xl:text-[25px] md:text-[22px] sm-[]">عرض</button>
                  </a>
              </div>
          </div>
          <div class="card xl:w-[650px] sm:w-[400px]">
              <div class="img"> <img src="../assets/aboutpage/1.png" alt=""></div>
              <div class="content">
                  <h3 class=" text-center">الهيكل التنظيمي</h3>
                  <a href="files/about/123.jpg" target="_blank">
                      <button class="mt-5 xl:text-[25px] sm:text-[22px] sm-[]">عرض</button>
                  </a>
              </div>
          </div>
        </div>

        <div class="xl:mt-[451px] md:mt-[200px] sm:mt-[80px]"></div>







    </div>
</template>
<style scoped>
.card{
    /* width: 300px; */
    height: auto;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    transition: all 0.6s ease;
}

.card:hover{
    box-shadow: #e2e1e1 -10px 10px, #87764E -17px 17px ;
}



.card .content{
    padding: 30px 70px;
}

.card .content h3{
    width: auto;
    font-weight: 300;
    font-size: 30px;
    padding-bottom: 5px;
    border-bottom: 4px solid #87764E;
    color: #87764E;
}

.card .content p{
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.card .content a{
    list-style-type: none;
    text-decoration: none;
}

.card .content button{
    font-family: 'Londrina Solid', cursive;
    font-weight: 100;
    color: #87764E;
    width: 100%;
    height: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    background-color: white;
    transition: 0.2s ease-in-out;
}

.card .content button:hover{
    background-color: #87764E;
    color: white;
    cursor: pointer;
}

</style>