<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">جوائز أدب الأطفال في العالم العربي رؤية مستقبلية</div>
            <img class=" mt-20 xl:w-[1200px] xl:h-[1000px] md:w-[800px]" src="../assets/seminarsPage/seminar3/1.png" alt="">
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">يستضيف منتدى الجوائز العربية ندوة افتراضية بعنوان: “جوائز أدب الأطفال في العالم العربي: رؤية مستقبلية”، يتحدث فيها كتاب أدب الأطفال: الدكتور طارق البكري/لبنان، والأستاذة سماح أبوبكر عزت/مصر، والأستاذة تغريد النجار/الأردن، والأستاذة هدى الشوا/الكويت، وتدير الحوار الرئيسة التنفيذية لمؤسسة عبدالحميد شومان فالنتينا قسيسية.<br>  ناقشت الندوة موضوعات مهمة، منها: هل يمكن للجوائز بما تطرحه من إصدارات أن تعالج مشاكل إنتاج الثقافة والمعرفة والإبداع في العالم العربي. وهل يمكن أن تعالج مشاكل وتحديات ضعف الإقبال على القراءة في العالم العربي؟ كيف يمكن لجوائز أدب الأطفال العربية مواكبة التطورات الحاصلة على أدب الطفل في العالم؟ ما الذي يتوجب توفره في كتب الأطفال: المعرفة والقيم التربوية أم التسلية، أم التنويع؟ أين جوائز أدب الأطفال من الأشكال الحديثة، مثل: الرسوم المتحركة، الكتاب المسموع، الألعاب الإلكترونية، أم أنه من غير الضروري أن تخصص لها جوائز معينة؟

        </div>
        <div class=" mt-20 grid place-content-center">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/Qj9tNoXFgjI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/3/1.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar3/valantina.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">فالنتينا قسيسية</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/3/2.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar3/tagred.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">تغريد النجار</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/3/3.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar3/smah.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">سماح أبوبكر عزت</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/3/4.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar3/tarq.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">طارق البكري</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/3/5.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar3/hoda.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">هدى الشوا</div>
            </div>
        </div>
        <div class="mt-20"></div>
    </div>
</template>