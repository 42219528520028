<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">الجائزة العربية ووصلها بالجائزة العالمية</div>
            <img class=" mt-20 xl:w-[1200px] xl:h-[1000px] md:w-[800px]" src="../assets/seminarsPage/seminar2/5.jpeg" alt="">
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">ضمن أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 6 سبتمبر 2021، ندوة ثقافية بعنوان: “الجائزة العربية ووصلها بالجائزة العالمية”، افتراضياً، شاركت فيها الروائية الاستاذة نجوى بركات من لبنان، والكاتب الأستاذ ياسين عدنان من المغرب، والكاتب الأستاذ سيد محمود من مصر، وأدار الندوة الشاعر والباحث الأستاذ مراد القادري من المغرب. وطرح الدكتور مراد القادري عدداً من التساؤلات حول الجوائز العربية ووصلها بالجوائز العالمية؟ وكانت هذه التساؤلات محورالنقاش.</div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="">
                <iframe class=" h-[200px] xl:w-[300px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/DGfJDN3Ns8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="">
                <iframe class=" h-[200px] xl:w-[300px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/UhO7m5a0xeQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="">
                <iframe class=" h-[200px] xl:w-[300px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/TGR3Or2IOug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="">
                <iframe class=" h-[200px] xl:w-[300px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/7YxlAwiBjB0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>

        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-36 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/2/1.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar2/6.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">مراد القادري</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/2/2.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar2/2.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">ياسين عدنان</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/2/3.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar2/3.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">نجوى بركات</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/2/4.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar2/4.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">سيد محمود</div>
            </div>
        </div>
        <div class="mt-20"></div>
    </div>
</template>