<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">توقيع مذكرة تفاهم بين منتدى الجوائز العربية والمنظمة العربية للتربية والثقافة والعلوم (الألكسو) </div>
            <div class=" grid place-content-center">
                <img class=" mt-20 xl:w-[1000px] xl:h-auto md:w-[800px]" src="../assets/conferencePage/1.jpg" alt="">
            </div>
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed"> وقع منتدى الجوائز العربية، والمنظمة العربية للتربية والثقافة والعلوم (الألكسو) في الثاني من ديسمبر 2021، مذكرة تفاهم بمقر المنظمة في تونس.   وتهدف المذكرة إلى تعزيز التعاون المشترك، والتبادل المعرفي في المجال العلمي والثقافي المشترك، والاستفادة من الخبرات وإمكانات المنتدى والمنظمة فيما بينهما، وتعزيز التواصل بين الطرفين.  والعمل على إشراك الجوائز العربية في برامج وأنشطة المنظمة، وإقامة الندوات والمحاضرات والمؤتمرات المشتركة الواقعة ضمن اهتمام الطرفين. 
            <br>
            وقد وقع المذكرة معالي الأستاذ الدكتور محمد ولد عمر مدير عام المنظمة العربية للتربية والثقافة والعلوم (الألكسو)، ورئيس منتدى الجوائز العربية الدكتور عبدالعزيز السبيل.  وحضر مراسم التوقيع، نيابة عن رئيس عام اللجنة الوطنية السعودية للتربية والثقافة والعلوم، الأمين العام للجنة الوطنية للتربية والثقافة والعلوم. 
        </div>
        <div class="mt-20"></div>
    </div>
</template>