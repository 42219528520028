
<template>
  <div class="font-bodyFont">
    <div>
        <video class=" w-full" src="../assets/HomePage/intro.mp4"  playsinline autoplay muted loop></video>
        
    </div>
    <div class="grid place-content-center text-center  xl:mt-0 md:mt-[40px] mx-[20px]">
      <div>
        <h1 class="text-[#796F58] xl:text-[32px] md:text-[30px] sm:text-[26px] xl:mt-[100px] font-heading1 font-bold">منتدى الجوائز العربية</h1>
        <p class="xl:text-[25px] sm:text-[20px] text-justify md:px-[40px] mt-[20px] xl:max-w-[1500px]">منتدى الجوائز العربية، تجمع عربي ثقافي، يضم في عضويته جوائز المنطقة العربية، المتاحة لجميع العرب، ومقره جائزة الملك فيصل بالرياض. يعمل المنتدى على الارتقاء بالجوائز العربية، وتعزيز مكانتها، والتنسيق والتعاون فيما بينها، لتطوير بيئة عربية، محفزة للإبداع، والتميز. يأتي ذلك من خلال تبادل الخبرات والمعلومات، واقتراح أفضل السبل لتجاوز التحديات التي تواجه الجوائز العربية. ويهدف المنتدى إلى تعزيز العمل العلمي والثقافي العربي المشترك، وترسيخ هوية الثقافة العربية، وحضورها في المحافل الدولية.</p>
      </div>
      <div class=" grid place-content-center">
        <iframe class=" xl:w-[900px] xl:h-[600px] xl:mt-16 md:h-[300PX] md:w-[450PX] sm:w-[400px] sm:h-[300px] md:mt-0 sm:mt-[50px]"  src="https://www.youtube.com/embed/6uK5proZFQ4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
    <div class="text-[#796F58] xl:text-[32px] md:text-[30px] text-center mt-32 font-heading1 font-bold ">آخر الأخبار</div>
      <div class=" grid place-content-center">
        <!-- <div class="bg-[#796F58] xl:w-[400px] h-[10px] sm:w-[300px] mt-3"></div> -->
      </div>
    <div class=" md:flex place-content-center md:grid-cols-3 mt-[80px] mb-[100px] xl:gap-20 md:gap-10">
      <div v-for="(item) in lastThreeItems" :key="item.newsId" class="bg-[#F4F4F4] xl:w-[400px] md:w-[330px] sm:w-auto h-auto rounded-xl md:mx-0 sm:mx-10 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 border" >
        <router-link :to="{ name: 'NewsInfo', params: {id: item.id}}">
          <img class="rounded-t-lg" :src="item.newsImg" alt="" />
          <div class="text-center text-[22px] sm:text-[19px] my-5">
            {{ item.newsheading }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {news} from './newspages/newsList'
  export default {
    data() {
        return {
            newsList:news,
        };
    },
    computed: {
      lastThreeItems() {
          return this.newsList.slice(-3)
      }
    }
    
  }
</script>