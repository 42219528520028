<template>
    <div class="font-bodyFont">
        <div class=" md:text-4xl sm:text-3xl text-center mt-16 font-heading1">كتيبات</div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-16 xl:gap-44 md:gap-32 sm:gap-y-28">
            <div class="files/issue/1.pdf">
                <a href="files/issue/1.pdf" target="_blank">
                    <img src="../assets/issuepage/1.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
            <div>
                <a href="files/issue/2.pdf" target="_blank">
                    <img src="../assets/issuepage/2.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-16 xl:gap-44 md:gap-32 sm:gap-y-28">
            <div class="">
                <a href="files/issue/3.pdf" target="_blank">
                    <img src="../assets/issuepage/3.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
            <div>
                <a href="files/issue/BriefingBooklet.pdf" target="_blank">
                    <img src="../assets/issuepage/4.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
        </div>
        <div class="md:text-4xl sm:text-3xl text-center mt-16 font-heading1">نشرات</div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-16 xl:gap-44 md:gap-32 sm:gap-y-28">
            <div class="">
                <a href="files/issue/5.pdf" target="_blank">
                    <img src="../assets/issuepage/5.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
            <div>
                <a href="files/issue/6.pdf" target="_blank">
                    <img src="../assets/issuepage/6.png" alt="">
                    <button class="bg-[#404145] w-full text-white text-[27px]">عرض</button>
                </a>
            </div>
        </div>
        <div class="mt-28"></div>
    </div>
</template>