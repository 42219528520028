<template>
    <div class="font-bodyFont">
        <div v-if="membersDetail">
            <div class=" grid place-content-center mt-[80px]">
                <img class="md:w-[600px] " :src="membersDetail.image" alt="">
            </div>
            <div class="xl:mx-[200px] md:mx-10 sm:mx-5">
                <p class=" md:text-3xl sm:text-2xl font-heading1 text-[#796F58] mt-5"> {{ membersDetail.PrizeName }} </p>
                <p class="xl:text-[25px] md:text-[22px] sm:text-[20px] leading-relaxed md:ml-10 text-justify mt-3 ">
                    {{ membersDetail.prizeDetails }} <br>  <a :href="membersDetail.prizeWebsite" target="_blank"><p class=" hover:text-[#796F58]">الموقع الإلكتروني</p></a>
    
                </p>
            </div>
            <div class="xl:mx-[200px] md:mx-10 sm:mx-5 mt-20">
                <p class=" md:text-3xl sm:text-2xl font-heading1 text-[#796F58]">{{membersDetail.representativeName}}</p>
                <!-- <p class=" xl:text-[25px] md:text-[22px] sm:text-[20px] text-gray-600 mt-3"> ممثل جائزة الملك عبدالله الثاني ابن الحسين للابداع</p> -->
            </div>
            <div class=" grid md:grid-flow-col md:auto-cols-auto mt-16 xl:mx-[200px] md:mx-10 sm:mx-5 md:gap-5">
                <div class=" place-content-center text-center">
                    <img class="h-auto w-[150px] rounded-lg xl:mt-[30px] md:mt-[30px]" :src="membersDetail.representativeimg" alt="">
                </div>
                <div>
                    <p class="text-justify xl:text-[25px] md:text-[22px] sm:text-[20px] leading-relaxed md:ml-10 mt-5 xl:ml-0" style="white-space: pre-line">
                        {{membersDetail.representativeDetails}}
                    </p>
                </div>
            </div>
        </div>
        <div class=" mt-40"></div>
    </div>
</template>
<script>
import { Members } from './MembersPages/MembersList';
export default {

props:{
    id: {type: Number, default: null},
},
data () {
    return {
        membersDetail: Members[this.id-1]
    }
},
}
</script>