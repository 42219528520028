<template>
  <div class="font-heading1">
    <div class="bg-[#F4F3EF] w-auto h-auto">
        
      <div class=" w-auto h-[120px] bg-[#F4F3EF] flex flex-row items-center">
        <div class="w-auto xl:h-[128px]  ltr:xl:ml-[120px] rtl:xl:mr-[75px] md:h-[75px] ltr:md:ml-[20px] rtl:md:mr-[20px] sm:h-[90px] ltr:sm:ml-[20px] rtl:sm:mr-[20px] items-center">
            <router-link to="/">
                <img class="h-[110px] xl:mt-2 md:-mt-5 sm:-mt-3" src="../assets/logo.png" alt=""/>
            </router-link>
        </div>
        <div class="flex-1 flex justify-between xl:mx-[100px] md:mx-[20px] md:flex sm:hidden font-bold xl:text-[22px]">
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/">الرئيسية</router-link></div>
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/members">أعضاء المنتدى</router-link></div>
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/about">عن المنتدى</router-link></div>
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/activities">اللقاءات</router-link></div>
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/news">الأخبار</router-link></div>
            <div class="mt-10 hover:underline underline-offset-4"><router-link to="/issues">الإصدارات</router-link></div>
        </div>
      </div>
      <div class="md:hidden ">
        <div class=" grid place-content-end">
        <button @click="showMenuButton = false" v-show="showMenuButton" class="-mt-[100px] ml-2 md:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
        </button>
        <button @click="showMenuButton = true" v-show="!showMenuButton" class="-mt-[100px] ml-2 md:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-gray-600">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        </div>
        <div class="flex flex-col md:hidden bg-[#F4F3EF] w-full h-auto" v-show="!showMenuButton">
            <router-link to="/" class="py-3 px-5 text-[20px]">الرئيسية</router-link>
            <router-link to="/members" class="py-3 px-5 text-[20px] hover:bg-[#BDA56B] hover:text-white"> أعضاء المنتدى</router-link>
            <router-link to="/about" class="py-3 px-5 text-[20px] hover:bg-[#BDA56B] hover:text-white">عن المنتدى</router-link>
            <router-link to="/activities" class="py-3 px-5 text-[20px] hover:bg-[#BDA56B] hover:text-white">اللقاءات</router-link>
            <router-link to="/news" class="py-3 px-5 text-[20px] hover:bg-[#BDA56B] hover:text-white">الأخبار</router-link>
            <router-link to="/issues" class="py-3 px-5 text-[20px] hover:bg-[#BDA56B] hover:text-white">الإصدارات</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            showMenuButton: true,
        }
    },
}
</script>