<template>
    <Header></Header>

    <RouterView />

    <Footer></Footer>
</template>

<script>
import Header from './views/HeaderPage.vue'
import Footer from './views/FooterPage.vue';


export default {
  components: {
    Header,
    Footer,
  },
}
</script>
