<template>
    <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959]"> الندوات </div>
    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
            <router-link to="firstSeminar" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar1/pic1.jpg" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">الندوه الاولى</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">ضمن أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 12 أبريل2021، ندوة ثقافية بعنوان: “المبدع العربي وكورونا.. معايشات الجديد!”، افتراضياً، شاركت فيها الروائية الدكتورة شهلا العجيلي من سوريا، والروائية الدكتورة زهور كرام من المغرب، والباحث الدكتور عبدالله إبراهيم من العراق،</p>
            </router-link> 
        </div>
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
            <router-link to="secondSeminar" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar2/5.jpeg" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">الندوه الثانية</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">من أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 6 سبتمبر 2021، ندوة ثقافية بعنوان: “الجائزة العربية ووصلها بالجائزة العالمية”، افتراضياً، شاركت فيها الروائية </p>
            </router-link> 
        </div>
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
            <router-link to="thirdSeminar" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar3/1.png" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">الندوه الثالثة</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">يستضيف منتدى الجوائز العربية ندوة افتراضية بعنوان: “جوائز أدب الأطفال في العالم العربي: رؤية مستقبلية”، يتحدث فيها كتاب أدب الأطفال: الدكتور طارق البكري/لبنان، والأستاذة سماح أبوبكر عزت/مصر، </p>
            </router-link> 
        </div>
    </div>
    <div class="xl:mt-[392px] md:mt-24 sm:mt-20"></div>
</template>
<style scoped>
  .textWithBlurredBg{
    display:inline-block;
    position:relative;
    transition:.6s;
    margin: 0;
    
  }
  
  .textWithBlurredBg img{

    transition:.3s;
    border-radius: 100%;

  }
  
  .textWithBlurredBg:hover img{
    filter:blur(2px) brightness(60%);
  }
   
  .textWithBlurredBg :not(img){
    position:absolute;
    z-index:1;
    top:30%;
    text-align:center;
    opacity:0;
    transition:.5s;
  }
  
  .textWithBlurredBg h3{
    top:50%

  }
  
  .textWithBlurredBg:hover :not(img){
    opacity:1;
    
  }

</style>