<template>
    <div class=" font-bodyFont"> 
        <div class="bg-[#404145] w-auto h-auto">
            <div class=" text-white text-sm grid md:grid-flow-col md:auto-cols-auto place-content-start">
                <img class="xl:h-auto  xl:w-[800px] md:w-[600px]" src="../assets/neww-scaled-1-1536x364-4.jpg" alt="">
                <div class=" md:mx-0 sm:mx-5  md:mb-0 sm:mb-5">
                    <div class="mt-8">
                        صندوق بريد 22476 الرياض 11495 المملكة العربية السعودية
                    </div>
                    <div>
                        هاتف 4652255 (966)
                    </div>
                    <div>
                        فاكس 4658685 (966)
                    </div>
                    <div>
                        بريد إلكتروني: <a href="mailto:info@arabprizes.org" class=" hover:text-[#BDA56B]">info@arabprizes.org</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>