<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">رعاية أولمبياد الطفل للبحث العلمي </div>
            <div class=" grid place-content-center">
                <img class=" mt-20 xl:w-[1000px] xl:h-auto md:w-[800px]" src="../assets/conferencePage/2.jpg" alt="">
            </div>
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">
            شارك منتدى الجوائز العربية في النسخة الثانية من أولمبياد المنظمة العربية للتربية والثقافة والعلوم (الألكسو) لتعزيز قدرات الطفل العربي في منهجيات البحث العلمي التي انطلقت أعماله مساء الثلاثاء (6 سبتمبر2022) في تونس العاصمة، برعاية المنتدى، وبحضور أكثر من 100 مشارك من خبراء وتلامذة ومتسابقين من 18 دولة عربية.
            يهدف الأولمبياد الذي استمر ثلاثة أيام تحت عنوان “المجال الرقمي في خدمة البحث العلمي” إلى التعريف بأهمية نشر ثقافة البحث العلمي والاهتمام بالموارد التعليمية، وتشجيع المتعلّمين وتحفيزهم على البحث والابتكار والعمل الجماعي، بغية بناء جيل عربي جديد يمتلك المعرفة العلمية، فيما شهد الأولمبياد أنشطة ترفيهية لتوطيد العلاقة بين المشاركين والمتحدثين، فضلاً عن عرض الفرق المشاركة.
            <br>
            وقال الأمين العام لجائزة الملك فيصل رئيس منتدى الجوائز العربية، الدكتور عبد العزيز السبيل في كلمته أمام الجلسة الافتتاحية للأولمبياد: نشرف بوجودنا في هذا الأولمبياد الذي يهدف إلى تنمية البحث والابتكار العلمي، وبث روح العمل الجماعي لدى الناشئة، مشيراً إلى أن منتدى الجوائز العربية، تجمع عربي علمي ثقافي، يضم في عضويته جوائز المنطقة العربية، المتاحة لجميع العرب.
            <br>
            وأضاف الدكتور السبيل: أود باسم صاحب السمو الملكي الأمير خالد الفيصل الرئيس الفخري للمنتدى، وكافة الأعضاء، تقديم خالص الشكر والتقدير لمعالي المدير العام للألكسو، وإلى الزملاء والزميلات في إدارة العلوم والبحث العلمي، الذين منحونا فرصة المشاركة في هذا الأولمبياد. ونطمح دوماً إلى تعزيز أواصر التعاون بين المنتدى والمنظمة، لتحقيق منجزات أفضل لأمتنا، في مجالات التربية والثقافة والعلوم.
            <br>
            وتوجه الدكتور السبيل في كلمته إلى الشباب المشاركين قائلاً: أنتم أملنا، أنتم مستقبلنا، أنتم قادة أمتنا في العلم والتفوق، أنتم من سيعيد مجد الأمة، لتعود للعالم الأول في مجال العلوم والتكنولوجيا، لتسهموا في بناء الإنسانية، ومد جسور المحبة والسلام، مؤكداً أنه يأمل من الألكسو ووزارات التربية العربية، التفكير في إقامة الأولمبياد كل عام، وزيادة أعداد المشاركين، ليكون ذلك محفزا بشكل أقوى لشبابنا العربي.
            وتمثل الفرق المشاركة في الأولمبياد كلاً من تونس والإمارات والبحرين والأردن والجزائر والمغرب والسعودية والسودان والعراق وعمان وفلسطين وقطر ولبنان والكويت وليبيا، ومصر، وموريتانيا، واليمن.

        </div>
        <div class="mt-20"></div>
    </div>
</template>