<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">ورشة الكتابة الابداعية</div>
            <img class=" mt-20 xl:w-[800px] xl:h-[600px] md:w-[800px]" src="../assets/workshop/Day3.png" alt="">
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">أقام المنتدى بتاريخ الإثنين 16 يناير، الثلاثاء 17 يناير، الاربعاء 18 يناير 2023 ورشة افتراضية مدتها ثلاثة أيام، قدمها: الأديب طالب الرفاعي. الورشة وجهت لكل كاتب يرغب بتطوير قدراته في كتّابة المحتوي على شبكة الانترنت. تحدث الأستاذ الرفاعي في اليوم الأول عن تعريف ودوافع الكتابة الإبداعية وخطواتها، ثم انتقل في اليوم الثاني إلى عناصر القصة القصيرة، وفي اليوم الثالث ختم الورشة بشرح مفهوم الكتابة العصرية وأهمية الرؤية والرسالة للقصة القصيرة، كما تطرق إلى خطوات نشر العمل الأول وعلاقة الكاتب بالناشر.</div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="">
                <iframe class=" h-[200px] xl:w-[400px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/eHmN9Oh0z8w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="">
                <iframe class=" h-[200px] xl:w-[400px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/2c1PtXu_bHI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="">
                <iframe class=" h-[200px] xl:w-[400px] md:w-[250px] sm:w-[300px]" src="https://www.youtube.com/embed/tgf-PHg1dco" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>

        <!-- <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="">
                    <img class="h-[200px] xl:w-[300px] md:w-[200px] sm:w-[300px] w-auto rounded-t-md" src="../assets/seminarsPage/seminar2/6.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#F4F3EF] text-[20px]">مراد القادري</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="">
                    <img class="h-[200px] xl:w-[300px] md:w-[200px] sm:w-[300px] rounded-t-md" src="../assets/seminarsPage/seminar2/2.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#F4F3EF] text-[20px]">ياسين عدنان</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="">
                    <img class="h-[200px] xl:w-[300px] md:w-[200px] sm:w-[300px] rounded-t-md" src="../assets/seminarsPage/seminar2/3.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#F4F3EF] text-[20px]">نجوى بركات</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="">
                    <img class="h-[200px] xl:w-[300px] md:w-[200px] sm:w-[300px] rounded-t-md" src="../assets/seminarsPage/seminar2/4.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#F4F3EF] text-[20px]">سيد محمود</div>
            </div>
        </div> -->
        <div class="mt-20"></div>
    </div>
</template>