<template>
    <div class="font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold"> الدورة الرابعة 3-4 أكتوبر 2023</div>
            <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3 text-center">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/10.jpg" alt=""/>
                <p>الجمعية العمومية</p>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/4.jpg" alt=""/>
                <p>المجلس التنفيذي</p>
            </div>
        </div>
            <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">
                عقد منتدى الجوائز العربية اجتماع دورته الرابعة يوم الثلاثاء الثالث من شهر أكتوبر، باستضافة من مؤسسة عبدالحميد شومان في عمّان.  وقد رأس الاجتماع الدكتور عبدالعزيز السبيّل رئيس المنتدى والأمين العام لجائزة الملك فيصل، المقر الدائم للمنتدى، بحضور أعضاء الجمعية العمومية للمنتدى الذين يمثلون ثلاثين جائزة عربية.
                <br>
                وقد رحب رئيس المنتدى بالجوائز التي انضمت مؤخراً لعضوية المنتدى.  وناقش الأعضاء سبل تطوير العمل في الجوائز العربية، وتم استعراض الأنشطة التي قام بها المنتدى من ندوات ومحاضرات وورش عمل، وكذلك المؤتمرات التي شارك بها.  وقد وافق المجلس على مقترح مؤسسة الفكر العربي بإنشاء منصة للجوائز العربية بالتنسيق مع المنتدى وستكون المنصة مرجعاً للجوائز العربية شاملة لكافة المعلومات المتعلقة بالجوائز.  من جانب آخر، اطلع الأعضاء على سير الدراسة الشاملة حول الجوائز العربية التي يقوم بها المجلس الأعلى للثقافة في مصر.  كما تم الاتفاق على عدد من الأنشطة المستقبلية.
                <br>
                وحيث انتهت فترة المجلس التنفيذي للمنتدى فقد ارتأى الأعضاء التجديد للمجلس لفترة أخرى وانضمام جائزة عيسى لخدمة الإنسانية إلى عضوية المجلس التنفيذي.
                وقد اجتمع المجلس التنفيذي بدورته الجديدة وتم انتخاب الأستاذة فالنتينا قسيسيه الرئيس التنفيذي لمؤسسة عبدالحميد شومان نائبا لرئيس المجلس التنفيذي، وبالتالي نائباً لرئيس المنتدى.
                <br>
                 
            </div>
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold mt-[50px]">نشاطات الدورة الرابعة</div>
            <div class="grid place-content-center">
                <!-- <img class=" mt-20 xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/7.jpg" alt=""> -->
            </div>
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">حضر الأعضاء حفل توزيع جوائز عبدالحميد شومان.  وخلال الحفل، الذي أقيم برعاية صاحب السمو الملكي ولي عهد المملكة الأردنية الهاشمية، ألقى رئيس المنتدى كلمة عبر فيها عن شكر المنتدى وامتنانه لاستضافة مؤسسة عبدالحميد شومان لاجتماع الدورة الرابعة للمنتدى.  وخلال الحفل قام المنتدى بتكريم عدد من الجهات من القطاع الخاص، نظراً لدورهم في دعم ورعاية الجوائز العربية.  وقد شمل التكريم البنك العربي في الأردن، والبنك التونسي، وشركة زين في السودان، وجامعة الشرق الأوسط الأمريكية في الكويت ومنتدى أصيلة.</div>

        <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] mt-20 font-heading1">المكرمون</div>
        
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/1.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/11.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">البنك العربي - الاردن</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/2.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/12.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">البنك التونسي - تونس</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/3.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/13.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">شركة زين للاتصالات - السودان</div>
            </div>

        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/4.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/14.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">جامعة الشرق الأوسط الأمريكية - الكويت</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/5.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/15.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">مؤسسة منتدى أصيلة - المغرب</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/4/6.jpg" target="_blank">
                    <img class="h-[250px] w-[320px] rounded-t-md" src="../assets/meetingsPage/meeting4/16.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px]">فالنتينا قسيسية</div>
            </div>
        
        </div>
        
        <!-- <div class=" text-center text-[#8C7959] md:text-3xl sm:text-2xl mt-20 font-heading1">الندوة الأولى: الواقع والرؤى المستقبلية</div> -->
        <div class=" mt-20 grid place-content-center">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/I0kL_jdfQ_E?si=ZNyHgDVfkYZ-EDez" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>



        <!-- <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/1.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/henry.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">هنري العويط</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/2.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/valantina.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">فالنتينا قسيسية</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/3.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/nakba.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">اسعد عبدالرحمن</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/4.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/400x400-150x150-1.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">على بن تميم</div>
            </div>
        </div> -->


        <!-- <div class=" text-center text-[#8C7959] md:text-3xl sm:text-2xl mt-20 font-heading1 font-bold">الندوة الثانية: الفائزون والأثر</div>
        <div class=" grid place-content-center mt-20">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/nr6Ckzmlb5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/5.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/1apf.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">شوقي بزيع</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/6.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/4apf.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">سعيد المصري</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/7.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/3apf.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">جوخة الحارثية</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/7.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/2apf.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">يوسف المحيميد</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/meetings/1/9.pdf" target="_blank">
                    <img class="h-[200px] rounded-full" src="../assets/meetingsPage/meeting1/hqdefault.png" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">مراد القادري</div>
            </div>
        </div> -->


        <div class=" text-center text-[#8C7959] text-2xl mt-20 font-heading1">صور اللقاء</div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-20 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/1.jpg" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/17.jpg" alt=""/>
            </div>
        </div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-10 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/18.jpg" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/19.jpg" alt=""/>
            </div>
        </div>
        <div class="grid place-content-center md:grid-flow-col md:auto-cols-auto xl:gap-10 md:gap-8 mt-10 sm:gap-y-8 md:mx-0 sm:mx-3">
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/20.jpg" alt=""/>
            </div>
            <div>
                <img class="xl:w-[600px] xl:h-[400px] md:w-[400px] md:h-[280px]" src="../assets/meetingsPage/meeting4/21.jpg" alt=""/>
            </div>
        </div>
        <div class="mt-20"></div>
    </div>
</template>
<style>


</style>