<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 xl:mx-[150px] md:mx-0 sm:mx-3 ">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959]  font-heading1 font-bold">مشاركة منتدى الجوائز العربية في المؤتمر والمعرض الدولي للعلوم 2023- "العلوم لمستقبل زاهر" الذي نظمته كلية العلوم، جامعة الملك سعود، الرياض </div>
            <div class=" grid place-content-center md:grid-flow-col md:auto-cols-auto md:gap-[20px]">
                <img class=" mt-20 xl:w-[500px] xl:h-auto md:w-[500px]" src="../assets/conferencePage/4.jpg" alt="">
                <img class=" mt-20 xl:w-[500px] xl:h-auto md:w-[500px]" src="../assets/conferencePage/5.jpg" alt="">
            </div>
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">
            شارك منتدى الجوائز العربية في المؤتمر والمعرض الدولي للعلوم في دورته الخامسة تحت شعار "العلوم لمستقبل زاهر" الذي نظمته كلية العلوم بجامعة الملك سعود 6-8 فبراير 2023. يهدف المؤتمر إلى إلقاء الضوء على القطاعات الحيوية والاستراتيجية للاقتصاد، ومن أهمها الطاقة المتجددة، وقطاع التعدين، وقطاع البتروكيميائية. 
            <br>
            في اليوم الأول من المؤتمر، نظم منتدى الجوائز العربية ندوةً علمية بعنوان" الجوائز العلمية وأثرها في دعم البحث العلمي". شاركت فيها المنظمة العربية للتربية والثقافة والعلوم "الألكسو" ومثلها الأستاذ الدكتور محمد سند أبودرويش مدير إدارة العلوم والبحث العلمي، ومؤسسة عبدالحميد شومان ومثلتها الأستاذة آلاء أبوليل مديرة إدارة البحث العلمي وتطوير البرامج، وجائزة الملك فيصل ممثّلةً في المهندس سليمان المزيد المستشار العلمي للجائزة، ومدير تحرير مجلة الفيصل العلمية، ومؤسسة الكويت للتقدم العلمي بتمثيلٍ من الدكتورة خولة الشايجي نائب مدير عام للمؤسسة، وأدار الندوة الأستاذ الدكتور زيد آل عثمان عميد كلية العلوم. وقد تحدث المشاركون عن جهود مؤسساتهم في دعم البحث العملي، ملقين الضوء على الجوائز العلمية التي ترعاها هذه المؤسسات والدور الذي تلعبه في تقدير العلماء والباحثين، وتحفيز الشباب للإنجاز العلمي.
            كما شارك منتدى الجوائز العربية بالمعرض المصاحب للمؤتمر بجناح مثّل المنتدى وأعضاءه.
        </div>
        <div class=" xl:mt-[316px] sm:mt-20"></div>
    </div>
</template>