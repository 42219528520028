<template>
<div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959] font-heading1"> اللقاءات </div>
    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 ">
            <router-link to="/firstMeeting" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting1/meeting1.jpeg" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">دورة الاول</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">في إطار احتفال جائزة الملك فيصل بمرور أربعين عاماً على منح الجائزة، نظمت الأمانة العامة للجائزة منتدى الجوائز العربية، الموافق للثالث والرابع من شهر أكتوبر 2018 الذي عقد في مقر الجائزة.</p>
            </router-link> 
        </div>
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
            <router-link to="/secondMeeting" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting2/1.jpeg" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">دورة الثاني</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">برعاية صاحب السمو الملكي الأمير خالد الفيصل مستشار خادم الحرمين الشريفين، أمير منطقة مكة المكرمة، رئيس هيئة جائزة الملك فيصل، عقد في مدينة الرياض، في الثالث من أكتوبر 2019 الاجتماع الثاني للجمعية العمومية </p>
            </router-link> 
        </div>
        <div class=" xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
            <router-link to="/thirdMeeting" class="textWithBlurredBg">
                <img class="xl:h-[400px] xl:w-[400px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting3/1.png" alt="" />
                <p class=" text-white xl:mr-[150px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[100px]">دورة الثالث</p>
                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">برعاية وحضور صاحب السمو الملكي الأمير خالد الفيصل، الرئيس الفخري لمنتدى الجوائز العربية، انطلقت الدورة الثالثة للمنتدى في السادس من أكتوبر 2021، بمعرض الرياض الدولي للكتاب،</p>
            </router-link> 
        </div>
    </div>
    <div class="xl:mt-[392px] md:mt-24 sm:mt-20"></div>
</template>
<style scoped>
  .textWithBlurredBg{
    display:inline-block;
    position:relative;
    transition:.6s;
    margin: 0;
    
  }
  
  .textWithBlurredBg img{

    transition:.3s;
    border-radius: 100%;

  }
  
  .textWithBlurredBg:hover img{
    filter:blur(2px) brightness(60%);
  }
   
  .textWithBlurredBg :not(img){
    position:absolute;
    z-index:1;
    top:30%;
    text-align:center;
    opacity:0;
    transition:.5s;
  }
  
  .textWithBlurredBg h3{
    top:50%

  }
  
  .textWithBlurredBg:hover :not(img){
    opacity:1;
    
  }




</style>