<template>
    <div class=" font-bodyFont">
        <div class=" grid place-content-center md:mt-32 sm:mt-24 md:mx-0 sm:mx-3">
            <div class="md:text-4xl sm:text-3xl text-center text-[#8C7959] font-heading1 font-bold">المبدع العربي و كورونا.. معايشات الجديد</div>
            <img class=" mt-20 xl:w-[1200px] xl:h-[1000px] md:w-[800px]" src="../assets/seminarsPage/seminar1/pic1.jpg" alt="">
        </div>
        <div class=" text-justify mt-20 xl:mx-[300px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">ضمن أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 12 أبريل2021، ندوة ثقافية بعنوان: “المبدع العربي وكورونا.. معايشات الجديد!”، افتراضياً، شاركت فيها الروائية الدكتورة شهلا العجيلي من سوريا، والروائية الدكتورة زهور كرام من المغرب، والباحث الدكتور عبدالله إبراهيم من العراق، وأدار الندوة الروائي الأستاذ طالب الرفاعي رئيس جائزة الملتقى للقصة القصير العربية. وطرح الأستاذ طالب الرفاعي عدداً من التساؤلات حول تسرب كورونا إلى ساعات وجدول أعمال المبدعين وتأثير هذه الجائحة على الإنتاج الأدبي وكانت هذه الأسئلة محور المناقشات بين المشاركين.
            
        </div>
        <div class=" mt-20 grid place-content-center">
            <iframe class=" xl:w-[1000px] xl:h-[600px] md:w-[600px] md:h-[400px] sm:w-[400px] sm:h-[280px]" src="https://www.youtube.com/embed/oEb7WOCmnuY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center mt-20 xl:gap-10 md:gap-8 sm:gap-y-8">
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/1/1.pdf" target="_blank">
                    <img class="h-[200px] w-[200px]  rounded-full" src="../assets/seminarsPage/seminar1/1.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">عبدالله إبراهيم</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/1/2.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar1/2.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">شهلا العجيلي</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/1/3.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar1/3.jpg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">زهور كرام</div>
            </div>
            <div class="transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                <a href="files/seminars/1/4.pdf" target="_blank">
                    <img class="h-[200px] w-[200px] rounded-full" src="../assets/seminarsPage/seminar1/4.jpeg" alt="" />
                </a>
                <div class="text-center rounded-b-md bg-[#87764E] text-white text-[20px] mt-3">طالب الرفاعي</div>
            </div>
        </div>
        <div class="mt-20"></div>
    </div>
</template>