<script >
    export default{
        data() {
            return {
                meetingCard: false,
                seminarCard: false,
                workshopCard: false,
                conferenceCard: false,
            };
        }
    }
</script>
<template class="scroll-smooth">
    <div class="font-bodyFont" style="scroll-behavior: smooth;">
        <!-- <div class="text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959]">
            فعاليات
        </div> -->
        <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-52 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
            <a href="#1" class="scroll-smooth">
                <button @click="(meetingCard = true), (seminarCard = false),(workshopCard = false), (conferenceCard = false)" class=" xl:h-auto xl:w-[310px]  md:h-auto md:w-[270px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                    <div> <img class=" w-auto h-auto mt-7" src="../assets/ActivitiesPage/1.png" alt=""></div>
                </button>
            </a>
            <a href="#1" class="scroll-smooth">
                <button @click="(meetingCard = false), (seminarCard = true),(workshopCard = false), (conferenceCard = false)" class=" xl:h-auto xl:w-[310px] md:h-auto md:w-[270px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                    <div> <img class=" w-auto h-auto mt-7" src="../assets/ActivitiesPage/2.png" alt=""></div>
                </button>
            </a>
            <a href="#1" class="scroll-smooth">
                <button @click="(meetingCard = false), (seminarCard = false),(workshopCard = true), (conferenceCard = false)" class=" xl:h-auto xl:w-[310px] md:h-auto md:w-[270px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 xl:p-4 sm:p-2 "> 
                    <div> <img class=" w-auto h-auto mt-7" src="../assets/ActivitiesPage/3.png" alt=""></div>
                </button>
            </a>
            <a href="#1" class="scroll-smooth">
                <button @click="(meetingCard = false), (seminarCard = false),(workshopCard = false), (conferenceCard = true)" class=" xl:h-[310px] xl:w-[310px]  md:h-auto md:w-[270px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                    <div> <img class=" w-auto h-auto mt-7" src="../assets/ActivitiesPage/4.png" alt=""></div>
                </button>
            </a>
        </div>
        

        <div class="mt-[0px] scroll-mt-[250px] scroll-smooth" id="1">
            <!-- meetings section start -->
            <Transition v-show="meetingCard" duration="1000" name="nested">
                <div class="outer">
                    <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959] font-heading1 font-bold "> الدورات </div>
                    <div class="text-justify mt-20 xl:mx-[400px] md:mx-[150px] sm:mx-3 xl:text-[23px] md:text-[22px] sm:text-[20px] leading-relaxed">انطلاقا من رغبة جائزة الملك فيصل في دعم وتطوير سبل التعاون في المجال العلمي والثقافي، والتبادل المعرفي بين مؤسسات الثقافة العربية المانحة للجوائز، فقد دعت إلى عقد منتدى الجوائز العربية للتعرف على معايير وآليات عمل الجوائز، والقواسم المشتركة بينها، وأوجه الاختلاف، بهدف تبادل الخبرات والتجارب، والاستفادة من الخبرات المتراكمة، والتجارب المتباينة، بين الجوائز.</div>
                    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300 ">
                            <router-link to="/firstMeeting" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting1/22.jpg" alt="" />
                                <p class=" text-white xl:mr-[135px] xl:text-[20px] md:text-2xl md:mr-[105px] md:-mt-8 font-heading1">الدورة الاولى</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">في إطار احتفال جائزة الملك فيصل بمرور أربعين عاماً على منح الجائزة، نظمت الأمانة العامة للجائزة منتدى الجوائز العربية، الموافق للثالث والرابع من شهر أكتوبر 2018 الذي عقد في مقر الجائزة.</p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/secondMeeting" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting1/11.jpg" alt="" />
                                <p class=" text-white xl:mr-[135px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الدورة الثانية</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">برعاية صاحب السمو الملكي الأمير خالد الفيصل مستشار خادم الحرمين الشريفين، أمير منطقة مكة المكرمة، رئيس هيئة جائزة الملك فيصل، عقد في مدينة الرياض، في الثالث من أكتوبر 2019 الاجتماع الثاني للجمعية العمومية </p>
                            </router-link> 
                        </div>

                    </div>



                    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/thirdMeeting" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting3/10.jpg" alt="" />
                                <p class=" text-white xl:mr-[135px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الدورة الثالثة</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">برعاية وحضور صاحب السمو الملكي الأمير خالد الفيصل، الرئيس الفخري لمنتدى الجوائز العربية، انطلقت الدورة الثالثة للمنتدى في السادس من أكتوبر 2021، بمعرض الرياض الدولي للكتاب،</p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/fourthMeeting" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/meetingsPage/meeting4/1.jpg" alt="" />
                                <p class=" text-white xl:mr-[135px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الدورة الرابعة</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">عقد منتدى الجوائز العربية دورته الرابعة يوم الثلاثاء الثالث من شهر أكتوبر 2023، باستضافة من مؤسسة عبدالحميد شومان في عمّان. </p>
                            </router-link> 
                        </div>

                    </div>




                </div>
            </Transition>

            <!-- seminars section start -->
            <Transition duration="1000" name="nested">
                <div v-show="seminarCard" class="outer">
                    <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959] font-heading1 font-bold"> الندوات </div>
                    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300">
                            <router-link to="firstSeminar" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar1/pic1.jpg" alt="" />
                                <p class=" text-white xl:mr-[145px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الندوه الاولى</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">ضمن أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 12 أبريل2021، ندوة ثقافية بعنوان: “المبدع العربي وكورونا.. معايشات الجديد!”، افتراضياً، شاركت فيها الروائية الدكتورة شهلا العجيلي من سوريا، والروائية الدكتورة زهور كرام من المغرب، والباحث الدكتور عبدالله إبراهيم من العراق.</p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="secondSeminar" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar2/5.jpeg" alt="" />
                                <p class=" text-white xl:mr-[145px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الندوه الثانية</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">من ضمن أنشطة منتدى الجوائز العربية، أقيمت، يوم الاثنين 6 سبتمبر 2021، ندوة ثقافية بعنوان: “الجائزة العربية ووصلها بالجائزة العالمية”، افتراضياً، شاركت فيها الروائية.</p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="thirdSeminar" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/seminarsPage/seminar3/1.png" alt="" />
                                <p class=" text-white xl:mr-[145px] xl:text-[20px] md:text-2xl md:-mt-8 md:mr-[105px] font-heading1">الندوه الثالثة</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">يستضيف منتدى الجوائز العربية ندوة افتراضية بعنوان: “جوائز أدب الأطفال في العالم العربي: رؤية مستقبلية”، يتحدث فيها كتاب أدب الأطفال: الدكتور طارق البكري/لبنان، والأستاذة سماح أبوبكر عزت/مصر.</p>
                            </router-link> 
                        </div>
                    </div>
                </div>
            </Transition>
            

            <!-- workshop section start -->
            <Transition duration="1000" name="nested">
                <div v-show="workshopCard" class="outer">
                    <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959] font-heading1 font-bold"> ورش عمل </div>
                    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/firstWorkshop" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/workshop/Day3.png" alt="" />
                                <p class=" text-white xl:mr-[90px] xl:text-2xl md:text-2xl md:-mt-8 md:mr-[85px] font-heading1">ورشة الكتابة الابداعية</p>
                                <p class="text-white xl:mx-5 xl:mt-12 md:mt-4 md:mx-6 text-justify ">أقام المنتدى بتاريخ الإثنين 16 يناير، الثلاثاء 17 يناير، الاربعاء 18 يناير 2023 ورشة افتراضية مدتها ثلاثة أيام، قدمها: الأديب طالب الرفاعي. الورشة وجهت لكل كاتب يرغب بتطوير قدراته في كتّابة المحتوي على شبكة الانترنت.</p>
                            </router-link> 
                        </div>
                    </div>
                </div>
            </Transition>

            <!-- conference section start -->
            <Transition duration="1000" name="nested">
                <div v-show="conferenceCard" class="outer">
                    <div class=" text-4xl xl:mt-32 md:mt-24 sm:mt-20 text-center text-[#8C7959] font-heading1 font-bold"> المؤتمرات </div>
                    <div class=" grid md:grid-flow-col md:auto-cols-auto place-content-center xl:mt-40 md:mt-24 sm:mt-20 xl:gap-20 md:gap-8 md:gap-y-0 sm:gap-y-16">
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/firstConferenc" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px] md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/conferencePage/1.jpg" alt="" />
                                <p class=" text-white xl:mr-[10px] xl:px-[30px] xl:text-[20PX] md:text-[16px] md:px-[30px] md:-mt-8 md:mr-[10px] font-heading1">توقيع مذكرة تفاهم بين منتدى الجوائز العربية والمنظمة العربية للتربية والثقافة والعلوم (الألكسو)</p>
                                <p class="text-white xl:mx-5 xl:mt-[50px] md:mt-[50px] md:mx-6 text-justify xl:px-[15px]"> وقع منتدى الجوائز العربية، والمنظمة العربية للتربية والثقافة والعلوم (الألكسو) في الثاني من ديسمبر 2021، مذكرة تفاهم بمقر المنظمة في تونس.   وتهدف المذكرة إلى تعزيز التعاون المشترك. </p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/secondConferenc" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px] md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/conferencePage/8.jpg" alt="" />
                                <p class=" text-white xl:mr-[60px] xl:px-[30px] xl:text-[20PX] md:text-[16px] md:px-[30px] md:-mt-8 md:mr-[40px] font-heading1">رعاية أولمبياد الطفل للبحث العلمي</p>
                                <p class="text-white xl:mx-5 xl:mt-[50px] md:mt-[50px] md:mx-6 text-justify xl:px-[15px]">شارك منتدى الجوائز العربية في النسخة الثانية من أولمبياد المنظمة العربية للتربية والثقافة والعلوم (الألكسو) لتعزيز قدرات الطفل العربي في منهجيات البحث العلمي التي انطلقت أعماله مساء الثلاثاء (6 سبتمبر2022) في تونس العاصمة.</p>
                            </router-link> 
                        </div>
                        <div class=" xl:h-[350px] xl:w-[350px]  md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] border-2 rounded-full shadow-lg border-[#BDA56B] transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 duration-300"> 
                            <router-link to="/thirdConferenc" class="textWithBlurredBg">
                                <img class="xl:h-[350px] xl:w-[350px] md:h-[300px] md:w-[300px] sm:h-[300px] sm:w-[300px] rounded-full" src="../assets/conferencePage/6.jpg" alt="" />
                                <p class=" text-white xl:mr-[10px] xl:px-[30px] xl:text-[20PX] md:text-[16px] md:px-[30px] md:-mt-8 md:mr-[10px] font-heading1">مشاركة منتدى الجوائز العربية في المؤتمر والمعرض الدولي للعلوم 2023</p>
                                <p class="text-white xl:mx-5 xl:mt-[50px] md:mt-[50px] md:mx-6 text-justify xl:px-[15px]">شارك منتدى الجوائز العربية في المؤتمر والمعرض الدولي للعلوم في دورته الخامسة تحت شعار "العلوم لمستقبل زاهر" الذي نظمته كلية العلوم بجامعة الملك سعود 6-8 فبراير 2023.</p>
                            </router-link> 
                        </div>
                    </div>
                    
                </div>
                
                
            </Transition>

        </div>
        <div class=" xl:mt-[616px] md:mt-[153px] sm:mt-20"></div>
    </div>
    
</template>
<style scoped>
  .textWithBlurredBg{
    display:inline-block;
    position:relative;
    transition:.6s;
    margin: 0;
    
  }
  
  .textWithBlurredBg img{

    transition:.3s;
    border-radius: 100%;

  }
  
  .textWithBlurredBg:hover img{
    filter:blur(2px) brightness(60%);
  }
   
  .textWithBlurredBg :not(img){
    position:absolute;
    z-index:1;
    top:30%;
    text-align:center;
    opacity:0;
    transition:.5s;
  }
  
  .textWithBlurredBg h3{
    top:50%

  }
  
  .textWithBlurredBg:hover :not(img){
    opacity:1;
    
  }





  /* .outer, .inner {
	background: #eee;
  padding: 30px;
  min-height: 100px;
} */
  
.inner { 
  background: #ccc;
}
  
.nested-enter-active, .nested-leave-active {
	transition: all 0.3s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner { 
  transition: all 0.3s ease-in-out;
}
/* delay enter of nested element */
.nested-enter-active .inner {
	transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  /*
  	Hack around a Chrome 96 bug in handling nested opacity transitions.
    This is not needed in other browsers or Chrome 99+ where the bug
    has been fixed.
  */
  opacity: 0.001;
}

</style>